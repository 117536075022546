import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false); // State to manage menu visibility
    const location = useLocation();

    // Scroll to top on route change
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <div className="flex flex-col bg-gray-900 text-white">
            {/* Navigation Bar */}
            <nav className="flex items-center justify-between px-8 py-4 bg-gray-900 fixed w-full z-10">
                {/* Logo */}
                <div>
                    <img
                        src={`${process.env.PUBLIC_URL}/Desync_Logo_No_Background.png`}
                        alt="Desync AI Logo"
                        className="h-10 w-auto"
                        style={{ filter: 'invert(1)' }}
                    />
                </div>

                {/* Hamburger Menu Button for Mobile */}
                <div className="md:hidden">
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="text-white focus:outline-none"
                    >
                        {/* Hamburger Icon */}
                        <svg
                            className="h-6 w-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                        >
                            {isOpen ? (
                                // Close Icon
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            ) : (
                                // Hamburger Icon
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 6h16M4 12h16M4 18h16"
                                />
                            )}
                        </svg>
                    </button>
                </div>

                {/* Navigation Links */}
                <ul className="hidden md:flex space-x-6 items-center">
                    <li>
                        <NavLink
                            to="/home"
                            className="hover:text-blue-400"
                            activeClassName="text-blue-400"
                        >
                            Home
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to="/team"
                            className="hover:text-blue-400"
                            activeClassName="text-blue-400"
                        >
                            Team
                        </NavLink>
                    </li>
                    <li>
                        <a
                            href="https://beta.desync.ai/login"
                            target="_blank"
                            rel="noreferrer"
                            className="border border-blue-500 px-4 py-2 rounded hover:bg-blue-500 hover:text-white"
                            activeClassName="bg-blue-500 text-white"
                        >
                            Sign In / Sign Up
                        </a>
                    </li>
                </ul>
            </nav>

            {/* Mobile Menu */}
            {isOpen && (
                <div className="fixed top-16 left-0 w-full bg-gray-800 md:hidden z-20">
                    <ul className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <li>
                            <NavLink
                                to="/home"
                                className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700"
                                activeClassName="bg-gray-900"
                                onClick={() => setIsOpen(false)}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/team"
                                className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-700"
                                activeClassName="bg-gray-900"
                                onClick={() => setIsOpen(false)}
                            >
                                Team
                            </NavLink>
                        </li>
                        <li>
                            <a
                                href="https://beta.desync.ai/login"
                                target="_blank"
                                rel="noreferrer"
                                className="block px-3 py-2 rounded-md text-base font-medium border border-blue-500 text-white hover:bg-blue-500"
                            >
                                Sign In / Sign Up
                            </a>
                        </li>
                    </ul>
                </div>
            )}

            {/* Content below the nav bar */}
            <div className="mt-16">
                {/* Rest of your page content goes here */}
            </div>
        </div>
    );
};

export default Navbar;
