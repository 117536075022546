import React, { useState } from 'react';
import { FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { motion } from 'framer-motion';
import './Team.css';

const teamMembers = [
    {
        name: 'Jackson',
        role: 'Senior Front End Dev',
        image: process.env.PUBLIC_URL + '/team/jackson.png',
        linkedin: 'https://www.linkedin.com/in/jackson-ballow-9888271bb',
        email: 'jackson@desync.ai',
        funFacts: [
            'Will break your ankles on the court.',
            'Loves fishing.',
            'Studying Desync sleeping patterns.',
            'Pretty good at frontend development.',
        ],
    },
    {
        name: 'Mark',
        role: 'Co-Founder (CEO)',
        image: process.env.PUBLIC_URL + '/team/mark2.png',
        linkedin: 'https://www.linkedin.com/in/mevgenev',
        email: 'mark@desync.ai',
        funFacts: [
            'Gray hair at 22.',
            'Can read 600 words per minute.',
            'Definitely American.',
            'Loves caffeine (maybe a little too much).'
        ],
    },
    {
        name: 'Maks',
        role: 'Co-Founder (CTO)',
        image: process.env.PUBLIC_URL + '/team/maks.png',
        linkedin: 'https://www.linkedin.com/in/maks',
        email: 'maks@desync.ai',
        funFacts: [
            '❤️ = Łódź.',
            '🚀 Loves rockets.',
            '🕵️ Fan of spies.',
            '🍕 Plain margherita pizza lover.',
        ],
    },
];

const advisors = [
    {
        name: 'Jamal Wilson',
        role: 'Advisor',
        image: process.env.PUBLIC_URL + '/team/jamal.png',
        linkedin: 'https://www.linkedin.com/in/jamaljwilson/',
        description: 'Advisor who has been with the team since day one.',
        logos: ['/images/fox_rothschild_logo.jpeg'],
    },
    {
        name: 'Michael J Frank',
        role: 'Operations Advisor',
        image: process.env.PUBLIC_URL + '/team/michael.png',
        linkedin: 'https://www.linkedin.com/in/michael-j-frank-boardmember/',
        description: 'Decades of experience from Wall Street floors to VC funds. Principal at Neuvation Ventures',
        logos: ['/images/neuvation_ventures_logo.png'],
    },
    {
        name: 'Malcolm Werchota',
        role: 'AI Advisor',
        image: process.env.PUBLIC_URL + '/team/malcolm.png',
        linkedin: 'https://www.linkedin.com/in/malcolmwerchota/',
        description: 'Known in Europe as Big Chief of GenAI in Business and founder of Coating AI.',
        logos: ['/images/coatingai_logo.jpeg', '/images/triple_eight_solutions_logo.jpeg'],
    },
    {
        name: 'Alan Gordon',
        role: 'Financial Advisor',
        image: process.env.PUBLIC_URL + '/team/alan.png',
        linkedin: 'https://www.linkedin.com/in/alan-c-gordon/',
        description: 'VC advisor with experience in VC, incoming analyst.',
        logos: ['/images/stepstone_group_logo.jpeg', '/images/battery_ventures_logo.jpeg', '/images/lead_edge_capital_logo.jpeg'],
    },  
];

const Team = () => {
    const [hoveredMember, setHoveredMember] = useState(null);
    const [randomFact, setRandomFact] = useState('');
    const [email, setEmail] = useState('');
    const [answer1, setAnswer1] = useState('');
    const [answer2, setAnswer2] = useState('');
    const [answer3, setAnswer3] = useState('');
    const [loading, setLoading] = useState(false);

    const handleMouseEnter = (member) => {
        setHoveredMember(member.name);
        const fact = member.funFacts[Math.floor(Math.random() * member.funFacts.length)];
        setRandomFact(fact);
    };

    const handleMouseLeave = () => {
        setHoveredMember(null);
        setRandomFact('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        const FORMSPARK_ENDPOINT = 'https://submit-form.com/jqt5dP1nq';

        try {
            await fetch(FORMSPARK_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    answer1,
                    answer2,
                    answer3,
                }),
            });
            
        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            alert('Thank you for submitting!');
            setEmail('');
            setAnswer1('');
            setAnswer2('');
            setAnswer3('');

            setLoading(false);
        }
    };

    const cardVariants = {
        initial: { opacity: 0, y: 30 },
        animate: (custom) => ({
            opacity: 1,
            y: 0,
            transition: { delay: custom * 0.1, duration: 0.5 },
        }),
    };

    return (
        <div className="min-h-screen bg-gray-900 text-gray-100 flex flex-col items-center py-10 px-6 pt-[7em]">
            {/* Header */}
            <header className="w-full py-5 mb-8">
                <h1 className="text-5xl font-bold text-center">Our Team</h1>
                <div className="divider mt-4"></div>
            </header>

            {/* Team Members */}
            <div className=" bg-gray-900 team-grid grid gap-10 md:grid-cols-3 mb-16">
                {teamMembers.map((member, index) => (
                    <motion.div
                        key={index}
                        className="team-card relative bg-dark-800 p-6 rounded-lg shadow-neumorphic-dark transition-all duration-300 group"
                        onMouseEnter={() => handleMouseEnter(member)}
                        onMouseLeave={handleMouseLeave}
                        variants={cardVariants}
                        initial="initial"
                        animate="animate"
                        custom={index}
                    >
                        <div className="relative">
                            <img
                                src={member.image}
                                alt={member.name}
                                className={`w-60 h-60 rounded-full object-cover transition-opacity duration-300 ${hoveredMember === member.name ? 'opacity-0' : 'opacity-100'
                                    }`}
                            />
                            {hoveredMember === member.name && (
                                <div className="absolute inset-0 flex items-center justify-center text-center bg-dark-800 bg-opacity-90 p-4 rounded-full shadow-inner">
                                    <p className="text-gray-200 font-medium">{randomFact}</p>
                                </div>
                            )}
                        </div>
                        <h2 className="text-2xl font-semibold mt-4">{member.name}</h2>
                        <p className="text-emerald-400 mb-3">{member.role}</p>
                        <div className="flex space-x-4 mt-2">
                            <a
                                href={member.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="icon hover:text-emerald-300"
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <span>
                                    <FaLinkedin />
                                </span>
                            </a>
                            <a
                                href={`mailto:${member.email}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="icon hover:text-emerald-300"
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <span>
                                    <FaEnvelope />
                                </span>
                            </a>
                        </div>
                    </motion.div>
                ))}
            </div>

            {/* Advisors */}
            <h2 className="text-4xl font-bold mt-12 mb-4">Advisors</h2>
            <div className="divider mb-8"></div>
            <div className="advisors-grid grid gap-8 md:grid-cols-2 lg:grid-cols-4 mb-20">
                {advisors.map((advisor, index) => (
                    <motion.div
                        key={index}
                        className="advisor-card relative bg-dark-800 p-5 rounded-lg shadow-neumorphic-dark text-center hover:shadow-inner-dark transition-all duration-300"
                        variants={cardVariants}
                        initial="initial"
                        animate="animate"
                        custom={index + teamMembers.length}
                    >
                        <img
                            src={process.env.PUBLIC_URL + advisor.image}
                            alt={advisor.name}
                            className="w-28 h-28 rounded-full object-cover mx-auto mb-4"
                        />
                        <h3 className="text-xl font-semibold mb-1">{advisor.name}</h3>
                        <p className="text-emerald-400 mb-2">{advisor.role}</p>
                        <p className="text-gray-400 text-sm">{advisor.description}</p>
                        <div className="flex justify-center items-center mt-4 space-x-4">
                            <a
                                href={advisor.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="icon hover:text-emerald-300 text-3xl md:text-4xl"
                            >
                                <FaLinkedin />
                            </a>
                            {advisor.logos && advisor.logos.map((logo, idx) => (
                                <img
                                    key={idx}
                                    src={process.env.PUBLIC_URL + logo}
                                    alt={`${advisor.name} Logo ${idx + 1}`}
                                    className="w-8 h-8 rounded-full object-cover"
                                />
                            ))}
                        </div>
                    </motion.div>
                ))}
            </div>

            {/* Poll Section */}
            <div className="w-full max-w-2xl bg-dark-800 p-8 rounded-lg shadow-neumorphic-dark text-center mb-12">
                <h2 className="text-3xl font-semibold mb-4">
                    We love eccentric people!<br />Think you got what it takes?
                </h2>
                <form className="space-y-6" onSubmit={handleSubmit}>
                    <div>
                        <input 
                            type="email" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="bg-gray-800 mb-7 w-full px-4 py-3 rounded-lg bg-dark-700 border border-gray-600 focus:outline-none focus:border-emerald-500 text-gray-100" 
                            placeholder="Your email" 
                            required
                        />
                        <label className="block text-lg text-gray-200 mb-2">
                            What is the one thing you can do better than anyone?
                        </label>
                        <textarea
                            value={answer1}
                            onChange={(e) => setAnswer1(e.target.value)}
                            className="bg-gray-800 w-full px-4 py-3 rounded-lg bg-dark-700 border border-gray-600 focus:outline-none focus:border-emerald-500 text-gray-100"
                            rows="3"
                            placeholder="Your answer"
                            required
                        ></textarea>
                    </div>
                    <div>
                        <label className="block text-lg text-gray-200 mb-2">
                            Favorite company of all time?
                        </label>
                        <textarea
                            value={answer2}
                            onChange={(e) => setAnswer2(e.target.value)}
                            className="bg-gray-800 w-full px-4 py-3 rounded-lg bg-dark-700 border border-gray-600 focus:outline-none focus:border-emerald-500 text-gray-100"
                            rows="3"
                            placeholder="Your answer"
                            required
                        ></textarea>
                    </div>
                    <div>
                        <label className="block text-lg text-gray-200 mb-2">
                            What one piece of advice do you live by?
                        </label>
                        <textarea
                            value={answer3}
                            onChange={(e) => setAnswer3(e.target.value)}
                            className="bg-gray-800 w-full px-4 py-3 rounded-lg bg-dark-700 border border-gray-600 focus:outline-none focus:border-emerald-500 text-gray-100"
                            rows="3"
                            placeholder="Your answer"
                            required
                        ></textarea>
                    </div>
                    <button 
                        type="submit"
                        className={`w-full bg-emerald-600 text-white py-3 rounded-lg font-semibold hover:bg-emerald-500 transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Team;
