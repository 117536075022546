// src/components/Home.js

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Parallax } from 'react-parallax';
import Slider from 'react-slick';
import AnimatedSVG from './AnimatedSVG';

import './Home.css';

const Home = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false); // To handle loading state

    const stats = [
        { title: "Pages Per Company", value: "100+" },
        { title: "Average Time To Search", value: "15 Seconds" },
        { title: "Time Saved Per Company", value: "20-30 Minutes" },
        { title: "Companies Analyzed", value: "500" },
        { title: "Number of Analysts", value: "35" },
    ];

    // Infinite carousel settings for smooth, circular scrolling
    const settings = {
        dots: false,
        infinite: true,
        speed: 6000,  // Increased speed for a smooth effect
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,  // Set to 0 for continuous movement
        cssEase: "linear",  // Linear easing for a steady scroll
        pauseOnHover: false,
        responsive: [
            { breakpoint: 1024, settings: { slidesToShow: 3 } },
            { breakpoint: 768, settings: { slidesToShow: 2 } },
            { breakpoint: 480, settings: { slidesToShow: 1 } }
        ]
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading

        // Replace with your actual Formspark endpoint
        const FORMSPARK_ENDPOINT = 'https://submit-form.com/BprBF7tJ6';

        try {
            await fetch(FORMSPARK_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });

        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setLoading(false); // End loading
        }
        alert('Thank you for joining our newsletter!');
        setEmail(''); // Clear the input field
    };

    return (
        <div className="bg-gradient-to-r from-slate-900 to-slate-700 min-h-screen flex flex-col text-white">
            
            {/* Hero Section */}
            <Parallax strength={500}>
                <div className="relative w-full h-full">
                    {/* SVG Background */}
                    <div className="absolute inset-0">
                        <AnimatedSVG />
                    </div>
                    {/* Overlay */}
                    <div className="absolute inset-0 bg-black bg-opacity-50"></div>
                    {/* Content */}
                    <section
                        id="home"
                        className="relative flex flex-col md:flex-row items-center md:items-start justify-between text-left md:text-left px-4 py-64 space-y-6 md:space-y-0"
                    >
                        {/* Text and Buttons */}
                        <div className="w-full md:w-1/2 max-w-2xl space-y-6">
                            <motion.h1
                                className="text-5xl md:text-6xl font-bold mt-8 text-white text-outline"
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1 }}
                            >
                                We Make Public Data Access Instant For Analysts
                            </motion.h1>
                            <p className="mt-4 mb-5 text-2xl text-white text-outline">
                                Say goodbye to hours of searching and hello to instant insights
                            </p>
                            <div className="flex space-x-4">
                                <a href="https://chromewebstore.google.com/detail/desync/oofiohlibnhpfleebafhjnjnmlcmnhch" target="_blank" rel="noreferrer">
                                    <button className="p-[3px] relative w-45">
                                        <div className="absolute inset-0 bg-gradient-to-r from-blue-100 to-blue-500 rounded-lg" />
                                        <div className="px-8 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent hover:text-black whitespace-nowrap">
                                            Get Extension
                                        </div>
                                    </button>
                                </a>
                                <a href="https://beta.desync.ai/signup" target="_blank" rel="noreferrer">
                                    <button className="p-[3px] relative w-40">
                                        <div className="absolute inset-0 bg-gradient-to-r from-blue-100 to-blue-500 rounded-lg" />
                                        <div className="px-8 py-2 bg-black rounded-[6px] relative group transition duration-200 text-white hover:bg-transparent hover:text-black">
                                            Sign Up
                                        </div>
                                    </button>
                                </a>
                            </div>
                        </div>
                        
                        {/* Demo Video */}
                        <div className="w-full md:w-1/2 flex justify-center mt-8 md:mt-0">
                            <iframe 
                                className="w-full h-64 md:h-96 max-w-full rounded-lg shadow-lg" 
                                src="https://www.youtube.com/embed/P2IUauRb6Yw" 
                                title="Demo Video" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                                allowFullScreen 
                            ></iframe>
                        </div>
                    </section>
                </div>
            </Parallax>


            {/* Problems Section */}
            <section id="problems" className="px-4 py-16">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl font-bold mb-8 text-center text-white text-shadow-neon">Challenges Today</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        <motion.div
                            className="p-6 rounded-lg bg-gray-800 hover:bg-gray-700 transition duration-300"
                            whileHover={{ scale: 1.05 }}
                        >
                            <h3 className="text-2xl font-semibold mb-4 text-blue-400">Data Is Expensive</h3>
                            <p className="text-gray-200">
                                We want to reduce the cost of data for everyone
                            </p>
                        </motion.div>
                        <motion.div
                            className="p-6 rounded-lg bg-gray-800 hover:bg-gray-700 transition duration-300 gradient-border-card"
                            whileHover={{ scale: 1.05 }}
                        >
                            <h3 className="text-2xl font-semibold mb-4 text-blue-400">Data Is Abundant</h3>
                            <p className="text-gray-200">
                                90% of the world's data was created in the last 2 years
                            </p>
                        </motion.div>
                        <motion.div
                            className="p-6 rounded-lg bg-gray-800 hover:bg-gray-700 transition duration-300"
                            whileHover={{ scale: 1.05 }}
                        >
                            <h3 className="text-2xl font-semibold mb-4 text-blue-400">Data Is Powerful</h3>
                            <p className="text-gray-200">
                                A VC that analyses 1,000 companies a year cannot compete with a VC that analyses 100,000
                            </p>
                        </motion.div>
                    </div>
                </div>
            </section>


            {/* Full-Width Smooth Wrapping Stats Carousel */}
            <section id="stats" className="py-16 w-screen">
                <div className="text-center mb-8">
                    <h2 className="text-3xl font-bold text-white text-shadow-neon">Product Impact</h2>
                </div>
                <Slider {...settings} className="w-full overflow-hidden">
                    {stats.map((stat, index) => (
                        <motion.div
                            key={index}
                            className="px-6"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                        >
                            <div className="p-8 bg-gray-800 rounded-lg shadow-lg flex flex-col items-center">
                                <h3 className="text-4xl font-semibold text-blue-400 mb-4">{stat.value}</h3>
                                <p className="text-gray-200 text-lg">{stat.title}</p>
                            </div>
                        </motion.div>
                    ))}
                </Slider>
            </section>


            {/* Data Sources Section */}
            <section id="data-sources" className="relative flex flex-col items-center overflow-hidden py-16 md:py-24">
                <div className="container relative z-[1] m-auto px-6 md:px-12">
                    <div className="m-auto text-center md:w-8/12 lg:w-6/12">
                        <h2 className="text-3xl font-bold text-white dark:text-white md:text-4xl">
                            Our Trusted <span className="text-primary">Data Sources</span>
                        </h2>
                        <p className="text-center text-gray-300 mt-4">
                            We gather only public data from trusted sources to provide valuable insights.
                        </p>
                    </div>
                    <div className="m-auto mt-10">
                        <div className="flex flex-wrap lg:flex-nowrap justify-center items-center gap-8 overflow-x-auto">
                            {/* Item 1 */}
                            <a
                                href="https://www.crunchbase.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group flex items-center justify-center rounded-xl border border-gray-200 dark:border-gray-600 p-4 hover:bg-opacity-10 h-36 w-36"
                            >
                                <img
                                    src="/images/crunchbase_logo.png"
                                    alt="Crunchbase"
                                    className="max-h-full max-w-full contrast-0 transition group-hover:contrast-100"
                                    loading="lazy"
                                />
                            </a>
                            {/* Item 2 */}
                            <a
                                href="https://pitchbook.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group flex items-center justify-center rounded-xl border border-gray-200 dark:border-gray-600 p-4 hover:bg-opacity-10 h-36 w-36"
                            >
                                <img
                                    src="/images/pitchbook_logo.png"
                                    alt="PitchBook"
                                    className="max-h-full max-w-full contrast-0 transition group-hover:contrast-100"
                                    loading="lazy"
                                />
                            </a>
                            {/* Item 3 */}
                            <a
                                href="https://www.cbinsights.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group flex items-center justify-center rounded-xl border border-gray-200 dark:border-gray-600 p-4 hover:bg-opacity-10 h-36 w-36"
                            >
                                <img
                                    src="/images/cb_insights_logo.png"
                                    alt="CB Insights"
                                    className="h-[7em] contrast-0 transition group-hover:contrast-100"
                                    loading="lazy"
                                />
                            </a>
                            {/* Item 4 */}
                            <a
                                href="https://www.gaebler.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group flex items-center justify-center rounded-xl border border-gray-200 dark:border-gray-600 hover:bg-opacity-10 h-36 w-36"
                            >
                                <span
                                    className="text-gray-500 font-bold text-7xl transition-colors duration-300 group-hover:text-yellow-500 !important"
                                    style={{ lineHeight: 1, position: 'relative', top: '-0.2em' }}
                                >
                                    g
                                </span>
                            </a>
                            {/* Item 5 */}
                            <a
                                href="https://parsers.me/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group flex items-center justify-center rounded-xl border border-gray-200 dark:border-gray-600 p-4 hover:bg-opacity-10 h-36 w-36"
                            >
                                <img
                                    src="/images/parsers_logo.png"
                                    alt="Parsers"
                                    className="max-h-full max-w-full contrast-0 transition group-hover:contrast-100"
                                    loading="lazy"
                                />
                            </a>
                            {/* Item 6 */}
                            <a
                                href="https://www.linkedin.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="group flex items-center justify-center rounded-xl border border-gray-200 dark:border-gray-600 p-4 hover:bg-opacity-10 h-36 w-36"
                            >
                                <img
                                    src="/images/linkedin_logo.png"
                                    alt="LinkedIn"
                                    className="h-[5em] contrast-0 transition group-hover:contrast-100"
                                    loading="lazy"
                                />
                            </a>
                            {/* Item 7 */}
                            <div className="group flex flex-col items-center justify-center rounded-xl border border-gray-200 dark:border-gray-600 p-4 h-36 w-36">
                                <img
                                    src="/images/www_logo.png"
                                    alt="World Wide Web"
                                    className="max-h-full max-w-full contrast-0 transition group-hover:contrast-100"
                                    loading="lazy"
                                />
                                <p className="text-xs text-gray-400 mt-2">Source: Root URLs</p>
                            </div>
                        </div>
                    </div>

                    <p className="text-center text-gray-400 mt-8">
                        <span className="text-gray-300 font-semibold">Coming soon:</span>
                    </p>
                    <div className="flex flex-wrap justify-center gap-8 mt-4">
                        <div
                            className="group flex items-center justify-center rounded-xl border border-gray-200 dark:border-gray-600 p-4 hover:bg-opacity-10"
                            style={{ height: '9em', width: '9em' }}
                        >
                            <img
                                src="/images/SEC_logo.png"
                                alt="SEC"
                                className="h-[5em] contrast-0 transition group-hover:contrast-100"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
            </section>


            {/* Pricing Section */}
            <section id="pricing" className="px-4 py-16">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl font-bold mb-8 text-center text-white text-shadow-neon">Pricing Plans</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                        {/* Free Plan */}
                        <motion.div
                            className="p-6 rounded-lg text-center bg-gray-800 hover:bg-gray-700 transition duration-300"
                            whileHover={{ scale: 1.05 }}
                        >
                            <h3 className="text-2xl font-semibold mb-4 text-blue-400">Free</h3>
                            <p className="text-4xl font-bold mb-4 text-white">
                                $0<span className="text-lg font-normal">/mo</span>
                            </p>
                            <ul className="mb-6 space-y-2 text-gray-200">
                                <li>5 free searches a day</li>
                                <li>Companies of your choosing</li>
                                <li>Response in 15-20s</li>
                            </ul>
                            <a href="https://beta.desync.ai/signup" target="_blank" rel="noreferrer" className="border border-blue-500 px-4 py-2 rounded hover:bg-blue-500 hover:text-white">
                                Get Started
                            </a>
                        </motion.div>

                        {/* Paid Plan with Responsive Scaling */}
                        <motion.div
                            className="p-6 rounded-lg text-center bg-gray-700 text-white md:transform md:scale-110 shadow-lg gradient-border-card"
                            whileHover={{ scale: 1.05 }}
                        >
                            <h3 className="text-3xl font-semibold mb-4 text-blue-400">Paid</h3>
                            <p className="text-5xl font-bold mb-4">
                                $30<span className="text-lg font-normal">/mo</span>
                            </p>
                            <ul className="mb-6 space-y-2">
                                <li>450 companies a month</li>
                                <li>No daily limits</li>
                                <li>Premium support</li>
                            </ul>
                            <a href="https://beta.desync.ai/signup" target="_blank" rel="noreferrer" className="bg-blue-700 px-6 py-2 rounded hover:bg-blue-500">Choose Plan</a>
                        </motion.div>

                        {/* Enterprise Plan */}
                        <motion.div
                            className="p-6 rounded-lg text-center bg-gray-800 hover:bg-gray-700 transition duration-300"
                            whileHover={{ scale: 1.05 }}
                        >
                            <h3 className="text-2xl font-semibold mb-4 text-blue-400">Enterprise</h3>
                            <p className="text-4xl font-bold mb-4 text-white">Custom</p>
                            <ul className="mb-6 space-y-2 text-gray-200">
                                <li>Custom setup</li>
                                <li>For VCs needing extensive data</li>
                                <li>Dedicated support</li>
                            </ul>
                            <a
                                className="border border-blue-500 px-4 py-2 rounded hover:bg-blue-500 hover:text-white"
                                href="https://usemotion.com/meet/mark-evgenev/meeting?d=20"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Contact Us
                            </a>
                        </motion.div>
                    </div>
                </div>
            </section>


            {/* Contact Form */}
            <section id="contact" className="px-4 py-16">
                <div className="max-w-4xl mx-auto">
                    <h2 className="text-3xl font-bold mb-8 text-center text-white text-shadow-neon">Join Our Newsletter</h2>
                    <form className="space-y-6 max-w-lg mx-auto" onSubmit={handleSubmit}>
                        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                            <input
                                type="email"
                                name="email"
                                placeholder="Your Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="border border-gray-600 w-full sm:w-96 px-4 py-2 rounded-lg focus:outline-none bg-gray-900 bg-opacity-50 text-white placeholder-gray-400"
                            />
                            <button
                                type="submit"
                                className={`bg-blue-600 w-full sm:w-auto px-8 py-2 rounded hover:bg-blue-500 mt-4 sm:mt-0 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                                disabled={loading}
                            >
                                {loading ? 'Joining...' : 'Join'}
                            </button>
                        </div>
                    </form>
                </div>
            </section>
        </div>
    );
};

export default Home;
