import React from "react";
import {
    BrowserRouter as Router,
    // Navigate,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";


import Navbar from "./Navbar/Navbar.js";
import Footer from "./Footer/Footer.js";

import Home from "./Home/Home.js";
import Team from "./Team/Team.js";
import Blog from "./Blog/Blog.js";

function Components() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/team" element={<Team />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
            <Footer />
        </Router>
    );
}

export default Components;