import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="w-full py-8 bg-gray-800">
            <div className="text-center text-white text-2x2">
                <p className="text-white">Follow us on social media:</p>
                <div className="flex justify-center space-x-6 mt-4">
                    <a
                        href="https://www.linkedin.com/company/desyncai/"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon shadow-lg text-3xl"
                        aria-label="LinkedIn"
                    >
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                    </a>
                    <a
                        href="https://www.youtube.com/@DesyncAI"
                        target="_blank"
                        rel="noreferrer"
                        className="social-icon shadow-lg text-3xl"
                        aria-label="youtube"
                    >
                        <FontAwesomeIcon icon={faYoutube} size="lg" />
                    </a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
